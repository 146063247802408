import React from "react";
import "./style.scss";

export const testimonialsContent = [
  {
    content:
      '"We have been working with the guys behind Casino Days before, and are truly happy with the partnership. We are looking forward to see how it will turn out this time. Great product, with great guys behind it."',
    name: "Håvard",
    affiliate: "https://www.norgescasino.com/",
  },
  {
    content:
      "We have started working on our casino section, and being familiar with the brand from another project there is no doubt who we are going to push for the target markets. We are running with CasinoDays on top.",
    name: "",
    affiliate: "https://betopia.com/",
  },
  {
    content:
      "We are happy to collaborate with Casino Days. The traffic we’ve sent so far converts really well and we’re already able to see phenomenal LTVs. It’s a pleasure seeing a long-term partner for the Indian market.",
    name: "",
    affiliate: "https://www.sevenjackpots.com/casino/",
  },
  {
    content:
      "Casino Days is an exciting addition to our best collaborators list. Great conversion, phenomenal LTVs, quick results - what more can you ask for? We're happy to have another partner on the Indian market that helps us achieve our goals.",
    name: "",
    affiliate: "https://www.guide2gambling.in/indian-casinos/",
  },
  {
    content:
      "Casino Days is an excellent partner for LiveCasinoGuide due to their excellent range of India focused live casino games and payment methods. We love a casino that jumps into a market with both feet!",
    name: "",
    affiliate: "https://www.livecasinoguide.in/",
  },
  {
    content:
      "Casino Days is a really good looking online casino in India. It conveys trust and delivers just that - as well as thousands of games and a great welcome bonus. Your players will love this casino and we can recommend it to anyone.",
    name: "",
    affiliate: "https://www.casinowings.com/india/",
  },
  {
    content:
      "We've been working with Casino Days for our Canadian brand for a while, and we couldn't be happier with their conversion rates and with how well our collaboration is performing.",
    name: "",
    affiliate: "https://casinoscanada.com/",
  },
  {
    content:
      "What we at www.lottoraden.se weren't prepared for, were just how much we would love to be an affiliate in Casino days affiliates. We really love the contact we have with Haris and the rest of the team. We see the brands as steady and nice brands with a high conversion rates. We also experience that we are seen and treated in a professional manner when we contact our manager, a feeling that makes the work both easy and nice. We at lottoraden highly recommend Casino days afiiliates. We believe that more people should experience this full-time player in the online gambling market.",
    name: "",
    affiliate: "https://www.lottoraden.se/",
  },
  {
    content:
      "Casino Days is one of our newest partners. They have an awesome affiliate team and a well known and high converting brand for Scandinavian traffic",
    name: "",
    affiliate: "https://casinoservice.org/",
  },
  {
    content:
      "Working with Casino Days has been a great experience, and we have had a great response from our visitors since adding it to our site.",
    name: "",
    affiliate: "https://www.nye-casino.com/",
  },
  {
    content:
      "We at 24hfreespins.com look forward to our new partnership with Casino Days. It's a beautiful online casino site, and their game selection and offers will suit our players from Norway and Canada. Thumbs up for their website and the affiliate team!",
    name: "",
    affiliate: "https://24hfreespins.com/",
  },
  {
    content:
      "Targeting the Indian market, it has been a no-brainer for us to partner up with rhino affiliates to promote Casino Days. The game selection at this site is absolutely amazing and payment methods are spot on for our Indian visitors. On top of that, the deal is fantastic. No negative carry over and up to 45% rev-share. Need I say more? We are very satisfied at the moment and look forward to a bright future with this great team!",
    name: "",
    affiliate: "https://gamblingbaba.com/gambling/casino-online-india/",
  },
  {
    content:
      "At Casibella we have been focusing hard to promote only the very top converting brands in our industry. With Casino Days all our targets are met and we can really recommend them for any one interested in what really matters - clicks to FTDs.",
    name: "",
    affiliate: "https://www.casibella.com/",
  },
  {
    content:
      "For our global audience at Casinoonline.casino we appreciate brands that can covert in many markets. In Casino Days we have a brand that has can take players from all our core markets and player value is really good!",
    name: "",
    affiliate: "https://casinoonline.casino/",
  },
  {
    content:
      "The CasinoViking loves to partner up with affiliate programs where communications are easy and deals are good. With Rhino Affiliates we have found just that and on top we see some substantive revenues so far.",
    name: "",
    affiliate: "https://casinoviking.com/",
  },
  {
    content:
      "Casino-Bonus.com only promotes the brands with the highest bonuses in the business. Casino Days provides solid bonuses and match the true intent of our readers that is clearly shown in the number of FTDs in Germany.",
    name: "",
    affiliate: "https://casino-bonus.com/",
  },
  {
    content:
      "We have been working with CasinoDays since launch, and are looking forward to launch another project with them on one of the top positions. We are sure this will be fruitful for both Parties.",
    name: "",
    affiliate: "https://gambly.com/",
  },
  {
    content:
      "We are delighted to be working with Rhino Affiliates. The platform is simple and very easy to understand. In addition, the platform has fast and professional agents. We look forward to cooperating together for a good period of time. We recommend them.",
    name: "",
    affiliate: "https://casinoking.co.nz/",
  },
  {
    content:
      'Rhino Affiliates is one of our favourite casino partners. People who work for this company are very professional and helpful, and we are sure that our cooperation will be long and fruitful. Besides, their premium casino - Casino Days - gives our business boost and motivation for further work. Our large gaming community at FreeSpinsGratis.com want to say "Thank You" for a very good cooperation. Keep it up!',
    name: "",
    affiliate: "https://freespinsgratis.com/",
  },
  {
    content:
      "On Casinobonus.in we promote products and online casinos of best quality for our users. Casino days do not only offer the audience a high-end entertainment, but the employees behind the Casino is very collaborative and experienced. We're looking forward to maintaining this partnership and grow together!",
    name: "",
    affiliate: "https://www.casinobonus.in/",
  },
  {
    content:
      "For The Gambler Bay, partnering with Your brand is a very important step. We have long been striving for this and believe that this is a mutually beneficial investment of forces. Your brand is the embodiment of functions that are absolutely necessary for modern affiliate programs. Playing for real money in online casinos is an important step, and you fully support the customers of your casinos!",
    name: "",
    affiliate: "https://www.thegamblerbay.com/",
  },
  {
    content:
      "Working in one of the most competitive markets in the world is harsh but with Casino Days we really solved huge issued with monetization. Props for the guys and keep up the good work!",
    name: "",
    affiliate: "https://www.besteonlinecasinoinorge.com/",
  },
  {
    content:
      "Getting a new brand like Casino Days to push at established markets was the best call we have done in the past year. Great conversions and excellent numbers! Thank you guys!",
    name: "",
    affiliate: "https://www.bestcasino.org/",
  },
  {
    content:
      "Rhino Affiliates have a great team and an exciting online casino to promote. We look forward for a long a lasting partnership in the Nordic region.",
    name: "",
    affiliate: "https://anbefaltcasino.com/",
  },
  {
    content:
      "It's great to be part of Rhino Affiliates. The traffic we send converts really well and the team is highly professional.",
    name: "",
    affiliate: "https://andarbaharbaba.in/",
  },
  {
    content:
      "Casino Days is the perfect partner for us. They offer great collection of Teen Patti games and plenty of Indian payment methods. Great stuff!",
    name: "",
    affiliate: "https://teenpattibaba.in/",
  },
  {
    content:
      "We are excited to work with Casino Days. Given the big game collection and a nice looking website we are confident this casino will be a big converter.",
    name: "",
    affiliate: "https://onlinecasinoguide.in/",
  },
  {
    content:
      "We recently started working with Casino Days and already achieved good results. If you are looking for high conversion and perfect affiliate service Rhino Affiliates is your choice.",
    name: "",
    affiliate: "https://slotcatalog.com/en",
  },
  {
    content:
      "SlotsCalendar has recently started working with Rhino Affiliates. We are just beginning this partnership, and are looking forward to promoting their exciting brand Casino Days. Thus far our interaction with the Rhino Affiliates team has been stellar. We hope to grow the partnership even more and make it work well for everybody.",
    name: "",
    affiliate: "https://www.slotscalendar.com/",
  },
  {
    content:
      "Rhinoaffiliatesに参加できることを光栄に思います。業界でもトップクラスのバラエティ豊富なゲーム数、日本向けに最適化されたサポート、そして魅力的な報酬は日本のオンラインカジノプレイヤーから受け入れられることでしょう。長き良きパートナーとなれることが嬉しく思います。",
    name: "",
    affiliate: "https://oncasitown.com/",
  },
  {
    content:
      "Rhinoaffiliatesに参加させていただけたことを嬉しく思います。ユーザーを1番に考えられたボーナス、わかりやすい操作画面などこれから日本ユーザーにも愛されていくオンラインカジノになっていくかと思います。これからよりたくさんのプレイヤーに参加してもらえるよう協力していきたいと思います。",
    name: "",
    affiliate: "https://online-casino-club.com/",
  },
  {
    content:
      "Working with RhinoAffiliates has been an absolute pleasure. Their product is great and easy to market and the team behind have many years experience and know what they are doing and we are happy to call them our partners!",
    name: "",
    affiliate: "https://www.casinotopp.net/",
  },
  {
    content:
      "After working with RhinoAffiliates and Casino Days for a while now, we are really happy with the results that we've gotten, and with the support from our manager. The cooperation has been great and beneficial for both parties.",
    name: "",
    affiliate: "https://www.casinotop.com/",
  },
  {
    content:
      "Casino Days is a very promising brand for the gambling market in India. With a great design and customised interface for Indian players we look forward to see promising results. Their affiliate team is professional and quick to respond. Can highly recommend for any site working with the Indian market!",
    name: "",
    affiliate: "https://allcasinos.in/",
  },
  {
    content:
      "A first class brand, providing first class service with a global audience. Casino Days brings together professionalism and dedication, creating the kind of affiliate we enjoy collaborating with. We foresee a bright future together and we can highly recommend them.",
    name: "",
    affiliate: "https://casino.online",
  },
  {
    content:
      "Casinodays is one of the most promising brands in the industry and we absolutely love their product. We are SUPER HAPPY to work with them.",
    name: "",
    affiliate: "https://casinobetting.live/in/",
  },
  {
    content:
      "We truly recommend working with RhinoAffiliates if you have traffic from the Nordics, India and/or Canada. Experienced and professional team behind who knows what the players are looking for in the different markets and know how to make the partnership profitable for both parties.",
    name: "",
    affiliate: "https://www.onlinegamblers.com/",
  },
  {
    content:
      "カジノデイズは、種類豊富かつ質の高いゲーム、幅広い決済方法、お得なボーナスなどプレイヤーにとって嬉しい特典が満載のカジノです。さらなるカジノデイズの発展を楽しみにしつつ、カジノデイズの良さをより多くのプレイヤーに広めていきたいと思います。これからも、長くお付き合いしていきたいと思えるパートナーです。",
    name: "",
    affiliate: "https://www.japanesecasinoreview.com/",
  },
  {
    content:
      "We are pleased about our collaboration with Rhino Affiliates, with their great offering and committed affiliate managers we have seen good results and overall working with them is a breeze.  Recommended!",
    name: "",
    affiliate: "https://www.gamblerspick.com",
  },
  {
    content:
      "We are very glad to work with RhinoAffiliates. We really love the brand it controls - Casino Days. Especially, because of support of some very specific but promising countries. Give it a try!",
    name: "",
    affiliate: "https://casinochap.com",
  },
  {
    content:
      "Casinodays is a great brand with good benefits. We have a good relationship with their affiliate manager. These are the 2 reasons we enjoy working with them. Surely you will too! ",
    name: "",
    affiliate: "https://pelipiste.com",
  },
  {
    content:
      "CanadaOnlineCasinos.com (COC) team is excited to work with Rhino Affiliates. Casino Days is a great choice for our Canadian audience. Commission plans are high & the team always ready to assist us. We are looking forward to a fruitfull long term cooperation.",
    name: "",
    affiliate: "https://canadaonlinecasinos.com",
  },
  {
    content:
      "CasinoDays and their team offers a great gaming experience for our visitors in India. With their flawless design and variety of payment/deposit options it's one of the leading brands right now.",
    name: "",
    affiliate: "https://bettingguide.com/in/casino/",
  },
  {
    content:
      "CasinoDays is an excellent brand which targets and actually converts Indian players offering fair and generous bonuses with a wide selection of casino games. Every month it performs well in our top10 recommended casinos. We think it deserves your attention if you are targeting the Indian market.",
    name: "",
    affiliate: "https://casinohex.in",
  },
  {
    content:
      "topjapanesecasinos.com is delighted to be working with Casino Days. The affiliate team is very approachable, responsive and ready to help solve queries whilst excellent decision making within the executive team is helping to raise the brand profile within the Japanese market. We are confident this casino will be a big converter and look forward to a long and fruitful partnership.",
    name: "",
    affiliate: "https://topjapanesecasinos.com",
  },
  {
    content:
      "We highly recommend Casino Days and Rhino Affiliates. It’s a very well-designed and highly converting brand in India. The affiliate team also replies fast and are very helpful.",
    name: "",
    affiliate: "https://livecasinoplaza.com/in/",
  },
  {
    content:
      "Working with Casino Days has only ups and no downs. In other words, we are delighted with our partnership. The brand converts very well and is a beautiful online casino suitable for many markets.",
    name: "",
    affiliate: "https://gambul.com/online-casino/",
  },
  {
    content:
      "We know the team behind CasinoDays very well, and trust that they have built a worldclass casino with great playervalue and conversion. Looking forward to be working together longterm.",
    name: "",
    affiliate: "https://onlinecasinoafrica.com",
  },
  {
    content:
      "GambgeGuys strives to deliver new and reliable brands to our readers. We are happy to cooperate with Casino Days!",
    name: "",
    affiliate: "https://gambleguys.com",
  },
  {
    content:
      "Working with Rhino affiliates is refreshing as can be, eventhough they are new in this industry, the team behind it is well know for their years of experience, which makes it super easy to work with them. We look forward to keep growing this partnership!",
    name: "",
    affiliate: "https://online.casino/affiliate-programs/rhino-affiliates/",
  },
  {
    content:
      "We are very happy with promoting Casino Days at Laatukasinot! Our customers love it - and so do we. Their team has been nothing but professional from day 1. Do not miss!",
    name: "",
    affiliate: "https://laatukasinot.com",
  },
  {
    content:
      "Casinodays affiliate team is one of the main reasons we joined this amazing brand. Great conversion, customer experience and all the most popular payment methods for the Indian market made this an easy decision for us.",
    name: "",
    affiliate: "https://casinofox.in",
  },
  {
    content:
      "Getting into India, you need a partner like Casinodays. We are super happy to have started this partnership and we are looking forward to what the future offers to both of us.",
    name: "",
    affiliate: "https://upicasinos.in/our-team/",
  },
  {
    content:
      "Having been inx the Indian market for some years, we can easily say that Casinodays is a must have brand. Fantastic affiliate team, always helpful and available. A great brand with good payment methods for our customer, this brand gets the highest recommendation from us.",
    name: "",
    affiliate: "https://casinowebsites.in",
  },
  {
    content:
      "カジノデイズはシンプルで使いやすさに長けているオンラインカジノです。AIを使ったサポートの精度も高く、オンラインカジノの中では、特に時代の流れにのっているのではないでしょうか。日本でも確実に人気が出ています。",
    name: "",
    affiliate: "https://casimaru.com",
  },
  {
    content:
      "カジノデイズは、ボリューム満点のゲーム数や、贅沢な入金不要の登録ボーナスなど、プレイヤー想いのサービス提供が見受けられます。カジノトップ５は、今後、カジノデイズがより多くのプレイヤーに楽しんでもらうとともに、ますます人気を伸ばしていくだろうと期待しています。",
    name: "",
    affiliate: "https://casinotop5.jp",
  },
  {
    content:
      "If you are serious affiliate you should work with team behind Casinodays, Maplecasinos have seen great results and can recommend them to any online casino sites.",
    name: "",
    affiliate: "https://maplecasinos.ca",
  },
  {
    content:
      "We are delighted to work with Rhino Affiliates. They have a huge selection of online slots for South African casino players and one of the most professional affiliate teams around. Great conversion rates, timely payments, and a proven track record for success. What else could you ask for?",
    name: "",
    affiliate: "https://www.southafricancasinos.co.za",
  },
  {
    content:
      "Casino Days is a splendid online casino! Their game selection is one of the largest on the market, promotions speak to the customers and support is top notch. We recommend fully!",
    name: "",
    affiliate: "https://www.kasinoranking.com/nettikasinot/",
  },
  {
    content:
      "When we saw Casino Days we were immediately impressed! And now as they are launching with pay n play we are more than excited to partner up with them!",
    name: "",
    affiliate: "https://www.pikakasinot.com",
  },
  {
    content:
      "What I like the most about Casino Days is the conversion rate and the retention of our players. It is a top performer on our website.",
    name: "",
    affiliate: "https://casinoohnelizenz360.com",
  },
  {
    content:
      "The affiliate team at Rhino Affiliates is super friendly. We are very happy with the partnership and are planning to promote Casino Days on all of our websites.",
    name: "",
    affiliate: "https://livecasinoohnelizenz.com",
  },
  {
    content:
      "Casino Days is one of those brands you will remember. Stunning design, great selection of games, and a brilliant affiliate team. We are happy to recommend Casino Days to anyone working with casino traffic.",
    name: "",
    affiliate: "https://www.grenzenloscasino.com",
  },
  {
    content:
      "One of the most anticipated partnerships on our end was the work with Rhino Affiliates. They offer a great online casino offering, with great bonuses and payment options for all players. We can definitely recommend working with them on and their great team.",
    name: "",
    affiliate: "https://www.bonuskoodit.com/nettikasinot/",
  },
  {
    content:
      "We had the opportunity to get to work with Rhino Affiliates from the get go off their pay n play, fast casino experience.  A casino without registration mean it’s a win, win, win situation (for the player, operator and affiliate). You definitely should work with them!",
    name: "",
    affiliate: "https://pika-kasinot.com/ilman-rekisteroitymista/",
  },
  {
    content:
      "Finally there is another great Brite casino available for Finnish players. Our site Kasinot Ilman Rekisteröitymistä is excited to list Casino Days as one of the few Brite casinos in the market. We are 100% sure that the brand is appreciated among our visitors. We love it ourselves too!",
    name: "",
    affiliate: "https://kasinot-ilman-rekisteroitymista.com/brite-kasinot/",
  },
  {
    content:
      "We are always on the lookout for new brands that will help us be grow and lead us to more leads. The AM is on top of things and his communication is efficient. We highly recommend working with Rhino Affiliates for everyone.",
    name: "",
    affiliate: "https://www.howtocasino.com",
  },
  {
    content:
      "The best acquisition team hands down. It is extremely easy to work with the Casino Days affiliate team and the casino itself is fantastic!",
    name: "",
    affiliate: "https://cricketbettingwali.in",
  },
  {
    content:
      "We have recently started working with CasinoDays but early indications are that this is a highly promising brand with both a unique concept and great team behind it. Haris is someone we know well and whose professionalism and knowledge compliment the brand's high performance, we hope our relationship continues long into the future.",
    name: "",
    affiliate: "https://nihoncasino.com/casinos/casino-days",
  },
  {
    content:
      "Rhino Affiliates are a great partner to work with. They have exciting brands, high conversion and a great affiliate team who are a pleasure to deal with.",
    name: "",
    affiliate: "https://www.casinoguidejapan.com/",
  },
  {
    content:
      "カジノデイズはオンラインカジノの中でも日本人にも使いやすい設計になっています。オンカジギャンブラーの酒場でも、カジノデイズをおすすめのオンラインカジノとしてギャンブラーに紹介する予定です。多くのオンカジユーザーにカジノデイズの良さを知ってもらえるようにがんばります。",
    name: "",
    affiliate: "https://www.onlinecasino-gambler.com/no-deposit-bonus/",
  },
  {
    content:
      "Kasinoseta.com lists all the best and new casinos without registration in the Finnish market and Casino Days defnitely has a place on the list. The team is easy to work with and the players love especially the mobile usability of the casino.",
    name: "",
    affiliate: "https://kasinoseta.com",
  },
  {
    content:
      "We are happy to work with Casino Days which is one of the best brands in the online casino industry in 2021. Casinos withouth registration are really popular at the moment so we are looking for a long and successful partnership with Rhino Affiliates.",
    name: "",
    affiliate: "https://pikakasinoja.com/kasinot-ilman-rekisteroitymista/",
  },
  {
    content:
      "Casino Days is one of the top brands in the Indian market. The conversion rate and CRM on this brand is very good. The affiliate team is very friendly and prompt in responses. Highly recommended.",
    name: "",
    affiliate: "https://winonlinecasino.in/",
  },
  {
    content:
      "Casino Days is a perfect place to monetize Indian traffic. Definitely, they and Roulette77 are perfect match. We even got several feedbacks from real players, and we must say they are completely satisfied!",
    name: "",
    affiliate: "https://roulette77.in/",
  },
  {
    content:
      "Casino Days is a really strong brand for us and we're glad to have such a great partnership with their affiliate team. The product is performing and we look forward to a long lasting cooperation with this team.",
    name: "",
    affiliate: "https://nettikasinoguide.com/",
  },
  {
    content:
      "We are very happy with Casino Days - their offers are very good and we see high valued Finnish players. They have Pay'n'Play payment methods - and our players love this!",
    name: "",
    affiliate: "https://www.veikkaajat.com/kasinot/casinodays/",
  },
  {
    content:
      "Casino Days are a super brand to have and we rate them highly. Great product in combination with a reliable affiliate team makes them a must have.",
    name: "",
    affiliate: "https://casinojungli.com/",
  },
  {
    content:
      "We have only recently partnered with Rhinoaffiliates.com, and we are seeing good results already. Very excited to be affiliated with this great brand. AAA+",
    name: "",
    affiliate: "http://gamblescope.com/",
  },
  {
    content:
      "The Rhino affiliate team is a blast to work with! Very responsive and communicative. Their brands offer amazing UX that helps greatly with conversion and retention thus keeping everyone happy. The quality of their brands and the timely payments make them a must-have for any affiliate site!",
    name: "",
    affiliate: "https://playorbet.com/in/casino/bonus/",
  },
  {
    content:
      "Working with Rhino Affiliates is smooth and their affiliate managers are always ready to assist. Their brand Casino Days is showing high player value which gives both sides a good feeling. Superb happy to work with them and building a long lasting co-operation.",
    name: "",
    affiliate: "https://nettikasinot-ilman-rekisteröitymistä.com/",
  },
  {
    content:
      "We are enjoying working with Rhino Affiliates and their superb brand Casino Days. Their account management team is professional and always ready to help out, player value is in really good level. ",
    name: "",
    affiliate: "https://nettikasino-ilman-rekisteröitymistä.com/",
  },
  {
    content:
      "Working with Casino Days and their affiliate program has been great, their partnership benefits both parties and we on bettingguide.com are more than happy to work with them. ***",
    name: "",
    affiliate: "https://bettingguide.com/",
  },
  {
    content:
      "We have been really happy working with Rhino affiliates and their brand Casino Days here at Jarttu84. The affiliate team is really active, supportive and helpful, payments are fast and most importantly, the customers really like the Casino Days brand and it is converting very well! Looking forward to a long and fruitful partnership!",
    name: "",
    affiliate: "https://www.jarttu84.com/",
  },
  {
    content:
      "Kasinoranking and Pikakasinot are always looking for great brands to work with and we are happy to find Casino Days! Their fast response from affiliate managers have helped us great to set everything up and we recommend them to everybody!",
    name: "",
    affiliate: "https://kasinoranking.com/nettikasinot/",
  },
  {
    content:
      "RouletteDOC has been working with Rhino for a long time, during the partnership we have been surprised by the responsiveness and communication of the partner program, we are glad that we have the opportunity to work with you.",
    name: "",
    affiliate: "https://roulettedoc.com/india/",
  },
  {
    content:
      "Working with Casino Days and their affiliate program has been super! Great product and non sticky bonuses combined with flexible agreements and swift communication makes both parties benefit and we on bonkku.com are more than happy to work with them.",
    name: "",
    affiliate: "https://bonkku.com/fi/non-sticky/",
  },
  {
    content:
      "Rhino Affiliates has been an amazing partner to AskGamblers, and for a good reason: they have a unique and professional approach that we admire, and one that we identify with their team's skills and the tools they use have consistently proven to be dependable, aiding in the improvement of our operations, which resulted in increased growth and value for our readers.",
    name: "",
    affiliate: "http://askgamblers.com/",
  },
  {
    content:
      "Casino Days is truly a top brand for the South African market that converts very well, offers generous bonuses and exciting games. CasinoHEX South Africa team is honored to partner with such professionals as Rhino Affiliates. We are glad to say their brand Casino Days is among the best 10 casinos for South African players. Thanks for our fruitful partnership!",
    name: "",
    affiliate: "https://casinohex.co.za/",
  },
  {
    content:
      "Working with Casino Days has been a delight. Their affiliate manager gives the possible service and is very responsive. We recommend everyone to start working with Rhino Affiliates today!",
    name: "",
    affiliate: "https://www.kongebonus.com/",
  },
  {
    content:
      "At Time2play, our experience with Rhino Affiliates has been nothing short of excellent! With Casino Days being an excellent brand and a responsive and helpful affiliate team, our partnership is a delight. We look forward to working with Rhino Affiliates in the future and would highly recommend them as a partner.",
    name: "",
    affiliate: "https://time2play.com/",
  },
  {
    content:
      "It is a pleasure for us to work with Rhino Affiliates. Work with Casino Days for almost a year and its convert amazing in Finland. We predict that the new PnP Kasino - Buusti - will be an equally magnificent success! Highly recommend!",
    name: "",
    affiliate: "https://www.uudetkasinot.com/kasino-ilman-rekisteroitymista",
  },
  {
    content:
      "We are thrilled to launch our partnership with Rhino affiliates. They impressed us especially with their Pay N Play brand Buusti, which we tested ourselves and enjoyed the functionality of the site. It providers all Pay n Play fans exactly they want.",
    name: "",
    affiliate: "https://pikakasinot.pro/",
  },
  {
    content:
      "We are proud and delighted to be able to partner up with the team at Rhino Affiliates. Their Pay N Play casino brands are among the very best online casino sites in Finland check our great list of No Registration casinos at Paynplaycasinos.com.",
    name: "",
    affiliate: "http://paynplaycasinos.com/",
  },
  {
    content:
      "We've been very pleased with our cooperation with Rhino Affiliates. Their two casino brands are easy to promote and their dedicated team is always willing to help us.",
    name: "",
    affiliate: "https://list.casino/",
  },
  {
    content:
      "We are always looking for partners who are easy to work with and offer us great insight on their brands. Rhino Affiliates is definitely one of those partners.",
    name: "",
    affiliate: "https://nettikasinolista.com/",
  },
  {
    content:
      "It was a very pleasant surprise to see that Rhino Affiliates had created a casino that is perfectly designed for Finnish customers. We cannot wait to see what the future holds.",
    name: "",
    affiliate: "https://kasinot.casino/",
  },
  {
    content:
      "With very close cooperation, https://casinotop3.com/ja/ has gone the extra mile from the very start of our collaboration. Always providing professional service in close cooperation has made us trust in them to always fulfill our goals https://casinotop3.com/ja/ and would highly recommend them to anyone.",
    name: "",
    affiliate: "https://casinotop3.com/ja/",
  },
  {
    content:
      "Casino Days is a great brand supported by a great affiliate team. We at Ayakacasinos are very grateful to be a part of Casino Days's journey.",
    name: "",
    affiliate: "https://ayakacasinos.com/",
  },
  {
    content:
      "Our partnership with Casino Days Affiliates is a very good experience. Casino Days is a trusted brand with perfect reputation, many qualities and sleek design. We look forward to a long and successful partnership!",
    name: "",
    affiliate: "https://japanesecasinos.com/",
  },
  {
    content:
      "Rhino Affiliates is a brilliant affiliate platform, both of the pikakasino brands are amazing and the team is so helpful and attentive to make sure you have all your needs met. They make sure that everything goes smoothly and you can enjoy an excellent experience as a marketing partner of this great company.",
    name: "",
    affiliate: "https://pikabonus.com/",
  },
  {
    content:
      "We are very happy to work with Rhino Affiliates. We believe that the cooperation will continue for a really long time, because they have great brands to market. Kasinokingit.com, we advertise only the best and most trusted online casinos. Casino Days and Buusti Kasino belong to this team really well. We definitely recommend Rhino Affiliates for new and old affiliates!",
    name: "",
    affiliate: "https://kasinokingit.com/",
  },
  {
    content:
      "เราเชื่อมั่นและอยากแนะนำเว็บ Casino Days จริงๆ ที่นี่เป็นคาสิโนที่มีเกมที่เร้าใจเรารู้สึกสนุกที่ได้เล่นคาสิโนที่นี่ นอกจากนี้ที่นี่ยังมีความปลอดภัยสูงอีกด้วย",
    name: "",
    affiliate: "http://www.thailandslots.com/",
  },
  {
    content:
      "Buusti is latest PnP brand to join our Finnish recommendations! Pikakasinot is all about fast and clean brands, with Buusti - Rhino have hit the jackpot! Its going to offer our Finnish customers exactly what they are looking for! Recommend them to everyone!",
    name: "",
    affiliate: "https://pikakasinot.com/",
  },
  {
    content:
      "Rhino Affiliates has proven to be an excellent partnership choice for us. Their affiliate program combined with the responsiveness and dedication of the team make us believe that this is just the beginning of a great and long-term journey.",
    name: "",
    affiliate: "http://ankaji.com/",
  },
  {
    content:
      "We are genuinely proud to have Rhino Affiliates in our portfolio. They are one of the most trustworthy brands you can encounter out there. A partner we can trust for many years. 10/10 recommended!",
    name: "",
    affiliate: "https://casinocountdown.com/",
  },
  {
    content:
      "A brand we can fully trust. A team of very competent professionals is available any time you need them. We can only expect the most positive results for our Japanese market out of this partnership. We highly recommend their affiliate program.",
    name: "",
    affiliate: "http://ikasaman.com/",
  },
  {
    content:
      "Thanks to the affiliate program from Casino Days we got more bang for the buck in our Indian market. Casino Days offers a wide range of interesting products that Indian players love. At Casinobetting.live we provide casino players with the best casino guides, this wouldn’t be possible without Casino Days.",
    name: "",
    affiliate: "https://casinobetting.live/in/online-casino/",
  },
  {
    content:
      "It's been a great pleasure for us to start a tight cooperation with Rhino Affiliates. It seems players like their Norwegian-speaking brand Casino Days for a good selection of games and payments as well as for nice customer experience. Keep it up!",
    name: "",
    affiliate: "https://norskcasinohex.com/",
  },
  {
    content:
      "They are the casinos of choice among our players. They also have a vast knowledge of the iGaming industry and they are always proactive in how they work. So we highly recommend RhinoAffiliates as a partner!",
    name: "",
    affiliate: "https://realcasinoscanada.com/",
  },
  {
    content:
      "It’s been such a pleasure for us to work with RhinoAffiliates. Their affiliate team is a great group of super professionals, and the casino itself is one of the best in the market right now. We have built a strong partnership with RhinoAffiliates and wish to continue cooperating in the future as well!",
    name: "",
    affiliate: "https://bonusetu.com/",
  },
  {
    content:
      "Casino Days provided us with quality and round-the-clock excellent customer service. They are always readily available to answer any questions we may have no matter how minute they may seem about the various affiliate programs present and the customer service support has various options that make it easier to find a solution to any questions and provide a quick response.",
    name: "",
    affiliate: "https://casinosworld.ca/",
  },
  {
    content:
      "The Rhino Affiliates' program is an impressive brand available on the iGaming market. Here at Daikichi we believe that they do not only provide an amazing experience, but excellent and advantageous contracts as-well.",
    name: "",
    affiliate: "https://xn--lck0a4d411qemf.jp/",
  },
  {
    content:
      "Casino Days offers a variety of trusted gaming options that are reliable and have a positive reputation in the online casino industry. More importantly, the gaming options have a huge selection to pick from to ensure we got everything we needed on one site and not feel left out of our favorite games.",
    name: "",
    affiliate: "https://casinoscad.com/",
  },
  {
    content:
      "We're very happy to recommend Buusti Kasino to anyone looking for a reliable partner! The brand provides our players with great value, which is clear when we look at the conversion and retention rates.",
    name: "",
    affiliate: "https://nettikasinot360.com/",
  },
  {
    content:
      "We are super excited about working with Rhino Affiliates. Their affiliate team is very helpful and friendly. Additionally, they offer great brands that our readers love. Buusti, their newest online casino in Finland looks great and performs very well.",
    name: "",
    affiliate: "https://uudetnettikasinot365.com/",
  },
  {
    content:
      "We at Casinos Analyzer have just started working with Rhino Affiliates. They provided us with all the necessary information we requested regarding their brand and best free slots. We consider their brands among the top Best Online Casinos. It's a pleasure to work with such a professional team!",
    name: "",
    affiliate: "https://casinosanalyzer.com/online-casinos",
  },
  {
    content:
      "It is pleasure to be working with Rhino affiliates and and promote their brand Casino Days. They have a remarkable casino brand with great conversion rate and high player value. Their welcome offers is also among the best. We are sure our relationship with Rhino Afilliates will remain a strong one. We highly recommend you to work together with them.",
    name: "",
    affiliate: "http://dailyspinsx.com/",
  },
  {
    content:
      "We have been working with the team behind Casino Days for several years, and we are very pleased to see how well their brands work. We are happy to recommend them.",
    name: "",
    affiliate: "https://uudet-pikakasinot-fi.com/",
  },
  {
    content:
      "Working with Rhino Affiliates is one of the best experiences we have had, they are an amazing team that worries for your necessities and always are willing to help you.",
    name: "",
    affiliate: "https://www.rahapelit-netissa.com/",
  },
  {
    content:
      "Rhino Affiliates has an incredible service; they are the definition of responsibility and proactivity without a doubt you van trust them to improve your brand.",
    name: "",
    affiliate: "https://www.uudet-nettikasinot.org/",
  },
  {
    content:
      "If you are looking for a great team that worries for your needs you can’t miss the chance of working with Rhino Affiliates, you can trust them because they worry for you and are always available.",
    name: "",
    affiliate: "https://www.parhaatnettikasinot.org/",
  },
  {
    content:
      "Casino Days ของ RhinoAffiliates เป็นแบรนด์คาสิโนที่มีตัวเลือกเกมให้เล่นมากมายทั้งสล็อตและเกมคาสิโน ทีมงานพันธมิตรคุยกับเราอย่างเป็นกันเองและช่วยเหลือทีมงานเราเป็นอย่างดี มีการช่วยกับปรับปรุงเพื่อให้ทราฟฟิคที่เราส่งไปทำการฝากได้อย่างง่ายดาย เรายินดีที่ได้ร่วมงานกับทีมงานระดับโลกเช่นนี้",
    name: "",
    affiliate: "https://www.livecasinothailand.com/",
  },
  {
    content:
      "เว็บ Casino Days มีตัวเลือกสล็อตเยอะเป็นระดับต้นๆของโลก ทีมงาน Affiliate ของ RhinoAffiliates เองก็มีความเป็นมืออาชีพสูง ทีมงานช่วยให้ conversion ที่เราส่งไปมีอัตราสูงกว่าเว็บทั่วไป เป็นหนึ่งในแบรนด์ระดับโลกที่มีความตั้งใจทำตลาดในประเทศไทย",
    name: "",
    affiliate: "https://www.demoslot.com/",
  },
  {
    content:
      "Having just joined with Casino Days we had warm welcome from the team. Thank you for that! Players love casino operators with a great game selection, campaigns and bonuses. At Casinoheroz we are proud to work with and promote such a great casino as Casino Days!",
    name: "",
    affiliate: "https://www.casinoheroz.com/",
  },
  {
    content:
      "RhinoAffiliates have an astonishing variety of bonus offers and promotions that will keep their players fully satisfied. We're very excited to start our cooperation and hope to grow our partnership for years.",
    name: "",
    affiliate: "https://casino358.com/",
  },
  {
    content:
      "We’ve been working with Rhino Affiliates for a while and we’re happy with the results we’ve achieved. If you’re looking for an affiliate service whose customer service is unmatched, you’ve found it. AboutSlots.com recommends adding Rhino Affiliates to your partners!",
    name: "",
    affiliate: "http://aboutslots.com/",
  },
  {
    content:
      "オンラインカジノおすすめナビ(OnlineCasino Osusume-Navi)「カジノデイズは日本人にも親しみやすいサイトデザインで、充実したゲーム環境などプレイヤーメリットの高さが大きな魅力と感じています。オンラインカジノおすすめナビは、今後もカジノデイズを優良カジノとして応援させていただきます。」",
    name: "",
    affiliate: "https://on-casi-navi.com/",
  },
  {
    content:
      "ทาง Casino Days ได้ดูแลเราเป็นอย่างดีและเป็นแบรนด์ที่ดีที่สุดที่เราเคยร่วมงานด้วย ทางผู้จัดการบัญชีเราได้ให้ความรู้และดูแลเราเป็นอย่างดี อีกทั้ง Rhino Affiliates มีเครื่องมือมากมายไว้ใช้สำหรับการตลาด",
    name: "",
    affiliate: "https://topthaicasino.net/",
  },
  {
    content:
      "We are thrilled to collaborate with Casino Days. So far their team had helped us with everything we've needed. The platform is simple and very friendly for the user. In addition, they offer such an amazing incentives for new players that join to their site.",
    name: "",
    affiliate: "https://www.casinocanuck.ca/",
  },
  {
    content:
      "What's not to love about Casino Days? Their conversion rate is phenomenal and their LTVs are off the charts! The team at Casino days provides us with such great results, we're thrilled that they've joined our list of top partners in Canada.",
    name: "",
    affiliate: "http://realcasinos.ca/",
  },
  {
    content:
      "カジノデイズは日本人のプライヤーにおすすめできる信頼性が高いオンラインカジノです。便利な入出金方法、幅広いカジノゲームや日本語対応カスタマサポートなどはカジノデイズをやっぱり各ギャンブラーの夢みたいなところにします。今後ともよろしくお願いします。",
    name: "",
    affiliate: "https://casinohex.jp/",
  },
  {
    content:
      "Buusti Kasino is a great addition to our site. We're expecting great results with them in this very competitive market. They have a strong team and we're very confident we can succeed with them.",
    name: "",
    affiliate: "https://paynplaycasinot.com/",
  },
  {
    content:
      "RhinoAffiliates have some excellent brands to their name and the products do all the talking. Nice results all around, we're grateful to have them onboard.",
    name: "",
    affiliate: "http://www.nettcasinoernorge.com/",
  },
  {
    content:
      "We signed up with Rhino Affiliates when they launched their first online casino and never regretted our decision. Listing their next brands became a no-brainer as they add great value to our portfolio and offer exciting gaming destinations for our players.",
    name: "",
    affiliate: "https://www.ristikaksi.com/",
  },
  {
    content:
      "Working with the team Rhino Affiliates has been a refreshing experience. Ever since they launched their first casino, we knew their brands would have a bright future in the online gaming world. Their affiliate team is highly professional, quick to answer and always very helpful.",
    name: "",
    affiliate: "https://casinovertailu.com/",
  },
  {
    content:
      "Rhino Affiliates has been a great partner right from the start. The amazing online casinos that our players love combined with a professional affiliate team is a really good package. Highly recommended!",
    name: "",
    affiliate: "https://www.gmblrs.com/",
  },
  {
    content:
      "Rhino’s brands are one of the best converting casinos in Finland. Highly recommend.",
    name: "",
    affiliate: "https://www.nettikasinot.org/",
  },
  {
    content:
      "Excited to continue our strong partnership with Rhino Affiliates which has strong brands and an even stronger team where the professionalism shines through.",
    name: "",
    affiliate: "https://www.casivo.ca/",
  },
  {
    content:
      "Excited to continue our collaboration with Rhino Affiliates with cutting edge products that deliver a good conversion throughout.",
    name: "",
    affiliate: "https://www.nettikasinot24.com/",
  },
  {
    content:
      "The brands by Rhino affiliates are at the top when it comes to converting Finnish customers, we are happy to continue this win-win collaboration.",
    name: "",
    affiliate: "https://www.kasinolinna.com/",
  },
  {
    content:
      "Rhino Affiliates' service is extremely relevant to our requirements. Rhino Affiliates is the ideal company to work with because of their professional service and transparent work ethic over the years. A strong collaboration with Rhino Affiliates ensures future success and more collaborative projects.",
    name: "",
    affiliate: "https://kasinokolikkopelit.com/",
  },
  {
    content:
      "We are always on the lookout for new brands to help us improve our services. Rhino Affiliates has provided us with a knowledgeable and helpful affiliate support team that consistently exceeds our expectations. Working with them is extremely simple and stress-free. This programme comes highly recommended by us.",
    name: "",
    affiliate: "https://xn--u9jxfraf9dygrh1cc8466k16c.com/",
  },
  {
    content:
      "We only recommend brands that we believe in and would like to work with again in the future. Rhino Affiliates unquestionably meets that standard. They are excellent partners for us, with a professional and knowledgeable team, and we look forward to working with them again in the near future.",
    name: "",
    affiliate: "https://mrcasinova.com/ja/",
  },
  {
    content:
      "It is always a pleasure to work with a professional team. As a result, our collaboration with Rhino Affiliates can only be described as positive. Throughout, they have been helpful and communicative. We are extremely proud of our collaboration with Rhino Affiliates and would strongly recommend them to anyone with serious intentions.",
    name: "",
    affiliate: "https://xn--eckle6c4f0gtcc8162j8zc.com/",
  },
  {
    content:
      "Casino Days has been one of our top performing brands in the Indian market. Good conversion, retention and a friendly account manager create a perfect package. We recommend working with Rhino Affiliates",
    name: "",
    affiliate: "https://www.livecasinoindia.com/",
  },
  {
    content:
      "We are delighted to be working with the successful team at X and we highly recommend them to anyone looking to grow their business. This innovative company is one of the best around. The team at CasinoTop3 look forward to seeing our partnership grow in the future.",
    name: "",
    affiliate: "https://casinotop3.com/new-online-casinos/",
  },
  {
    content:
      "We immediately partnered with this brand when we heard about their success. Our company has been working with them for a while now and we are more than happy to recommend them to anyone. They offer a high level of professionality, and this has been portrayed in our impressive results.",
    name: "",
    affiliate: "https://casinotop3.com/fi/uudet-nettikasinot/",
  },
  {
    content:
      "Rhinoaffiliates is one of the best affiliate in the market with great variety of brands. They are totally results driven with great response and conversion rate. We are looking forward to cooperating with them for many years to come.",
    name: "",
    affiliate: "https://www.caziwoo.com/",
  },
  {
    content:
      "It's our storng recommendation to cooperate and work with Rhinoaffiliets. The response rate is very high, the experience of working with their professional team is great and we plan on a long-term relationship with them.",
    name: "",
    affiliate: "https://www.nettikasinot.com/",
  },
  {
    content:
      "Having recently started working with Rhino Affiliates, we are more than positively surprised how things work with them. No issues whatsoever, and conversion rates are at a very good level. Great, competitive account managers!",
    name: "",
    affiliate: "https://veikkaajille.com/",
  },
  {
    content:
      "We've been working with Rhino Affiliates for over a year now and have had nothing but positive experiences. The deals are fair, the affiliate managers are excellent, and we've seen great results in terms of our bottom line. We're pleased to recommend them to anyone looking for a top-notch affiliate experience.",
    name: "",
    affiliate: "https://www.kasinohai.com/",
  },
  {
    content:
      "Since I've added CasinoDays to my site I have seen registrations and FTDs for players from India increase fivefold when compared to the previous brands I promoted.",
    name: "",
    affiliate: "http://livecasinocomparer.com/",
  },
  {
    content:
      "Rhino Affiliates is one of the best partners that the Саsinoonlineca.ca team has had to work with. We are delighted with the professionalism of the team. I can highly recommend Rhino Affiliates for any site dealing with the Canadian market!",
    name: "",
    affiliate: "https://casinoonlineca.ca/",
  },
  {
    content:
      "As a new affiliate portal on the block it was essential for us to pick the absolute best brands available. Lucky Spins did not disappoint. Matter of fact it even exceeded our expectations. Looking forward for many more years of collaboration.",
    name: "",
    affiliate: "https://nyecasinokongen.com/",
  },
  {
    content:
      "We enjoy working with the Rhino Affiliates team, as they are professional, responsive, and very helpful. We are excited to push their brands in Japan!",
    name: "",
    affiliate: "https://www.casinowired.com",
  },
  {
    content:
      "Working with Rhino Affiliates is always a great pleasure. Our Affiliate Manager is very professional, easy to talk to, and ready to help solve any inquiries. We’re very confident that Rhino Affiliates will remain one of our best and favorite partners for a long time.",
    name: "",
    affiliate: "https://allcasinos.jp",
  },
  {
    content:
      "Norwegian players want speed, good game selection, multiple deposit methods and most importantly; fast payouts. The brands of Rhino Affiliates satisfy all of these factors. As a partner of Rhino Affiliates, we receive the support needed to convert more players, and get paid on time for doing so which is why we recommend you give them a try too! Especially if you have players who prefer crypto casinos!",
    name: "",
    affiliate: "https://hasardspiller.com/beste-norske-casino/",
  },
  {
    content:
      "Players always struggle with payment methods, this have never been an issue with RhinoAffiliates brands. We are happy that they focus on crypto as a payment option as well, and we are for sure going to work closer with them moving forward.",
    name: "",
    affiliate: "https://cryptocasinosites.org/",
  },
  {
    content:
      "We are truly happy working with RhinoAffiliates, their brands are among the best converting brands for our target market at the moment. If you have traffic from Scandinavia you should really be working with them, if not you are missing out.",
    name: "",
    affiliate: "https://www.norskeautomater.com/",
  },
  {
    content:
      "カジノデイズは、プレーヤーやアフィリエイターからの声に耳を傾け、柔軟に意見を取り入れ、日々改善・改良を心がけていらっしゃいます。丁寧で迅速な対応と気遣いがあり、一緒に気持ちよく働くことができる担当者さんたちです。パートナーとして提携することをおすすめいたします。",
    name: "",
    affiliate: "https://www.casinotop10.jp",
  },
  {
    content:
      "カジノデイズは、プレーヤーやアフィリエイターからの声に耳を傾け、柔軟に意見を取り入れ、日々改善・改良を心がけていらっしゃいます。丁寧で迅速な対応と気遣いがあり、一緒に気持ちよく働くことができる担当者さんたちです。パートナーとして提携することをおすすめいたします。",
    name: "",
    affiliate: "https://casinomentor.com/",
  },
  {
    content:
      "We've been promoting Rhino brands to our players in several regions and the feedback we've received has been top. These sites are great for conversion and retention thanks to some of the great features on offer. The Rhino Affiliates team have also been great from the start to us, providing any info or help we needed. Highly recommended",
    name: "",
    affiliate: "https://casino-professor.com/",
  },
  {
    content:
      "Working with Rhino has been a really great experience for us. Our players from Finland love their casinos and features. And their affiliate team are highly professional and approachable. We highly recommend them for Finnish affiliates!",
    name: "",
    affiliate: "https://www.nettikasinot.media",
  },
  {
    content:
      "We strongly recommend any affiliate with traffic from Finland to partner up with Rhino Affiliates. We've enjoyed a very successful partnership since starting with them. The entire team have been easy to talk to and we've always received payments on quickly and on time.",
    name: "",
    affiliate: "https://www.pelikasinot.org/",
  },
  {
    content:
      "「私はカジノデイズのアフィリエイトパートナーとして選ばれたことに感謝しています。ゲーム数はもちろんながら分離型ボーナスを数多く配布している当カジノはユーザーにとって非常にメリットのあるカジノであり、私が感銘を受けた部分の一つです。アフィリエイトを通して当カジノの発展を心よりお祈り申し上げます。」",
    name: "",
    affiliate: "https://iwacasi.com/",
  },
  {
    content:
      "「業界トップクラスの5,000種類以上のゲームを取り揃えており、ライブロトなどカジノデイズならではのライブカジノも取り揃えており、非常にオススメできるオンラインカジノです。」",
    name: "",
    affiliate: "https://online-casino.media/",
  },
  {
    content:
      "Our cooperation with rhinoaffiliates stands at a very high level. We have a very good conversion and our manager is very helpful. Our minimitalletuskasinot.com team highly recommends working with rhinoaffiliates.",
    name: "",
    affiliate: "https://minimitalletuskasinot.com/brite-kasinot/",
  },
  {
    content:
      "We have been cooperating with Rhino Affiliates since they launched Casinos Days and never regretted our decision. Naturally, we added their next brands to our sites too. High conversion rates, Pay N Play, excellent retention tools and a very responsive affiliate team.",
    name: "",
    affiliate: "https://pikakasinot-suomi.com/",
  },
  {
    content:
      "Exciting online gaming destinations with Pay N Play facility and great deals for players and affiliates alike. Adding Rhino Affiliates brands should be on everyone’s list. A premium affiliate program with a professional team and on-time payments.",
    name: "",
    affiliate: "https://s-kasinot.com/",
  },
  {
    content:
      "Top team, quick to answer and always helpful. Listing Rhino Affiliates casinos on our sites was an easy decision, and they quickly showed their potential. Any new brand they may launch in future will instantly become part of our portfolio too.",
    name: "",
    affiliate: "https://uudetpikakasinot.com/",
  },
  {
    content:
      "We are exited to start a strong and valuable partnership with Rhino Affiliates. They have interesting brands with amazing features which we are happy to offer to our visitors at Voitolla.com",
    name: "",
    affiliate: "https://voitolla.com/netticasino/",
  },
  {
    content:
      "We highly recommend Big Boost's affiliate program. They have a great variety of games and promotions and the commission rates are very competitive.<br> Working with Lucky Spins has been a very positive experience. The team is easy to work with and the regular promotions and bonuses keep players engaged.<br>The collaboration with CasinoDays has been a great experience from day one. Everything is done in a professional manner and CasinoDays is offering great services and bonuses to our users. Definitely looking forward to working with this brand in the future.",
    name: "",
    affiliate: "https://www.casinon.in/",
  },
  {
    content:
      "We've been working with Casino Days across a number of markets for quite a while now and during this long partnership we've been really happy with our time working together. We're excited about the ongoing opportunity as we look to continue to establish in existing markets as well as our new markets.",
    name: "",
    affiliate: "https://www.bonus.ca/fr/casinodays",
  },
  {
    content:
      "We at NorsKecasinoSider.net are proud to partner with Casino Days for quite some time now and it's been a pleasure working alongside them. They have always been friendly, responsive and helpful with any question. Their brands are trustworthy not only in Norway but all over the world. All players are satisfied! We highly recommend working with Casino Days and Rhino Affiliates.",
    name: "",
    affiliate: "https://www.norskecasinosider.net/",
  },
  {
    content:
      "Our team is excited to promote Casino Days and other great brands through our partnership with Rhino Affiliates. They have some of the best online casinos in Norway. They also offer high quality services for Norwegian players. For all these reasons, we highly recommend working with the Rhino Affiliates team.",
    name: "",
    affiliate: "https://www.oppdagenettcasinoer.com/",
  },
  {
    content:
      "Rhino Affiliates is a solid and reliable partner with the best casino brands for Norwegian players. We hope that our future cooperation will be as pleasant, successful and joyful as it is now. NorgeOnlineCasino.com team.",
    name: "",
    affiliate: "https://www.norgeonlinecasino.com/",
  },
  {
    content:
      "We at CasinoEktePenger.net appreciate our collaboration with Casino Days. The Casino Days team is very reliable and their communication and conversion is exceptional, which makes them a dream collaboration. They are one of the most popular and reliable casino brands in Norway. We are proud of our relationship with them.",
    name: "",
    affiliate: "https://casinoektepenger.net/",
  },
  {
    content:
      "I've been working together with Rhino Affiliates for some time now and my experience as their partner has been great since the beginning. I look forward to many more years together.",
    name: "",
    affiliate: "https://casinojan.com/",
  },
  {
    content:
      "Even though we just recently started working with Rhino Affiliates we have been very impressed by their professionalism. Their online casinos seem to be converting nicely among our Canadian users making our cooperation a successful one!",
    name: "",
    affiliate: "https://bojoko.ca/casino/",
  },
  {
    content:
      "Slotsia（スロッティア）は、Casino Daysを日本市場に紹介することに誇りを持っています。この素晴らしいブランドは、驚くほど高いサービスレベルとブランド力によって、日本のプレイヤーたちから熱烈な支持を受けています。そして、今後もますます成長が期待されるオンラインカジノブランドです。私たちは、この素晴らしいパートナーシップを築くことができることを大変嬉しく思います。一緒に楽しみながら、大きな成功を収めましょう！",
    name: "",
    affiliate: "https://slotsia.com/ja/",
  },
  {
    content:
      "オンカジギャンブル情報ではカジノデイズ様の新規登録ボーナスを提供しています。カジノデイズ様は独自キャラクターによるプロモーションが特徴的で、日本人に馴染みやすいオンラインカジノとなっています。アフィリエイト担当の方ともやり取りが非常にスムーズに行うことができ、信頼あるオンカジとして推薦できます。カジノデイズ様では積極的なプロモーション展開を行っていますので、今後も当サイトで随時最新情報を提供していきたいと思います。",
    name: "",
    affiliate: "https://onlinecasino-gamble.jp/casino/nyuukinfuyou/",
  },
  {
    content:
      "RHINO Affiliatesは、管理画面も見やすく簡単にCVリンクの取得から進捗の確認までできるので助かっています。やり取りに関してもスムーズに丁寧に対応してくれるので、アフィリエイト初めての方にもおすすめです。",
    name: "",
    affiliate: "https://casinolobby.info/ranking/",
  },
  {
    content:
      "RHINO Affiliatesのカジノデイズを宣伝することができとても光栄です。特別ボーナスも頂くことができ、より好条件でプレーヤーにオンラインカジノを提供することができています。決済手段も豊富なことからとても遊びやすくコンバージョンに繋げやすいオンラインカジノです。",
    name: "",
    affiliate: "https://doramahjong.com/media/ranking/",
  },
  {
    content:
      "As an affiliate marketer, I've worked with numerous affiliate programs over the years, but Rhino Affiliates stands out as one of the best. The team at Rhino Affiliates is always responsive, professional, and easy to work with, making it a pleasure to promote their brands. Their commission structure is fair and transparent, and they offer a wide range of marketing materials that make it easy to promote their brands effectively. Overall, I would highly recommend Rhino Affiliates to anyone looking for a top-notch affiliate program in the online gaming industry.",
    name: "",
    affiliate: "https://starwarscasinos.com/",
  },
  {
    content:
      "Rhino Affiliates is a great partner for anyone looking to stand out in a crowded market. Their creative and original marketing approach has helped us differentiate ourselves from our competitors. Their brands are some of the most recognizable in the industry, which has helped us to attract new players and grow our business. We appreciate their reliable and responsive managers, who are always available to answer our questions and provide quality 24/7 support.",
    name: "",
    affiliate: "https://casino-en-chile.cl/",
  },
  {
    content:
      "私たちのRhinoアフィリエイトとの協力は素晴らしいものでした。彼らのチームは非常にプロフェッショナルで、常に迅速な対応をしてくれました。彼らの力を借りて、私たちのビジネスは大きく成長しました。本当にありがとうございました。",
    name: "",
    affiliate: "https://www.casino-studio.com",
  },
  {
    content:
      "Any affiliate looking to partner with a reputable and successful online casino should visit Rhinoaffiliates.com. Their team is dedicated to ensuring the success of their partners, and they offer a variety of bonuses and promotions to keep players interested. Communication with account managers is excellent, and the commission structure is fair and transparent. We have had a positive and profitable experience working with Rhinoaffiliates.com.",
    name: "",
    affiliate: "https://www.svindel.info/nettcasino/",
  },
  {
    content:
      "We have been working with Aleksandra on several projects earlier, and we are really happy with having him as an account manager at RhinoAffiliates. Looking forward to make this a win/win situation for both parties, and can highly recommend anyone with Scandinavian traffic to work with them.",
    name: "",
    affiliate: "https://www.nettcasino.com/",
  },
  {
    content:
      "RhinoAffiliates have been a great partner since day one, we are seeing great value and conversion for all their brands in our main markets. We recommend working with them.",
    name: "",
    affiliate: "https://www.nyecasino.me/",
  },
  {
    content:
      "It was a wonderful day for our team when we realized that Buusti now has an Estonian license. We only recommend MGA and EMTA casinos to our Finnish audience and are super excited to start promoting Rhino Brands.",
    name: "",
    affiliate: "https://turvallisetkasinot.com/",
  },
  {
    content:
      "Rhino brings everything Finns want to the table. Fast payments, fair bonuses, and an excellent selection of games. Happy to list these brands on our TOP list.",
    name: "",
    affiliate: "https://kasinot24.com/",
  },
  {
    content:
      "Buusti has been missing only one feature of being a perfect Finnish brand - a license that means tax-free winnings to Finns. Now with the Estonian license, we are super pleased about listing Rhino brands to our high-traffic tax-free casinos list.",
    name: "",
    affiliate: "https://verovapaatnettikasinot.org/",
  },
  {
    content:
      "We welcome Casino Days to the Estonian market! Casino Days offers a really comfortable gaming experience and great payment options. The selection of games is also very good.",
    name: "",
    affiliate: "https://kasiino21.com/",
  },
  {
    content:
      "Signing the collaboration deal with Rhino Affiliates, a prominent casino company is one of the right steps we made. We have already recorded tremendous success in the Canadian market thanks to the excellent services and products of our affiliate and pushed the boundaries of innovation.",
    name: "",
    affiliate: "https://casinosapproved.info/",
  },
  {
    content:
      "When it comes to affiliates, Rhino Affiliates are the best one to be working with. They are great in every way possible and their team works hard to ensure that everything works smoothly and nicely.",
    name: "",
    affiliate: "https://casinoschilenos.online/",
  },
  {
    content:
      "It was a simple and pleasant job collaborating with Rhino Affiliates , considering all aspects of the matter, such as consulting service, respectful treatment (that was also really fast and precise), pricing, and simplicity of cooperation. Play Safe always chooses the most experienced, modern, and flexible affiliate companies.",
    name: "",
    affiliate: "https://playsafecanada.org/",
  },
  {
    content:
      "カジノデイズさんのデザインはとても日本人の目に馴染みやすいデザインに仕上がっていて目を惹きます。マネークリックのアフィリエイターさんにもバナーが非常に好評です。そしてゲームが5000種以上と種類が豊富。必ずお気に入りが見つけられるので幅広い客層にアプローチできます。不明点があれば日本人スタッフの方が非常に親身になって対応してくださいます。今後もマネークリックとアフィリエイター 一丸となってカジノデイズさんの宣伝に注力して参ります、宜しくお願いいたします。",
    name: "",
    affiliate: "https://www.moneclicks.com/",
  },
  {
    content:
      "カジノデイズは独自の可愛らしいキャラクターが印象的でブランド力が強いカジノです。入出金方法も豊富で定期的にボーナスも行っているのでユーザーにとっては飽きがこない使いやすいと思います。これからも良いパートナーとして協力できるようにお力添えできれば光栄です。",
    name: "",
    affiliate:
      "https://compaffi.com/onlinecasino/no-deposit-bonus/all-no-deposit-bonus/",
  },
  {
    content:
      "カジノデイズはプレイヤーファーストを意識したサイト設計になっており、誰でも簡単に登録～出金ができるような優れたUXを提供しています。優秀なスタッフも在籍いるので、何か困ったことがあればすぐに解決してくれるでしょう。",
    name: "",
    affiliate: "https://www.casino-winnersclub.com/casino-ranking.php",
  },
  {
    content:
      "カジノデイズは素晴らしい入金ボーナスやフリースピン特典があり、ユーザーから絶大な信頼を得ています。サポートも業界トップクラス、初心者ガイドなどビギナーでも理解できるよう丁寧に解説されています。パートナーとして今後も積極的におすすめしていきたいと思います。",
    name: "",
    affiliate: "https://www.xn--lck0a5auxk.jp/",
  },
  {
    content:
      "Rhino Affiliates, as our outstanding partner, receives our immense gratitude for their unwavering support and professionalism. Their extensive network and effective marketing strategies elevate our brand's visibility and profitability. With their priority on our success, they are an invaluable ally in the competitive iGaming industry. We wholeheartedly endorse them for unparalleled growth and achievements.",
    name: "",
    affiliate: "https://leafletcasino.com/",
  },
  {
    content:
      "Very excited to be working with a brand like (insert brand) The staff at Rhino are always super helpful! Recommended",
    name: "",
    affiliate: "https://oddstips.io/beste-casino/",
  },
  {
    content:
      "We highly recommend Rhino affiliates for their exceptional service, prompt payments, effective communication, and diverse brand portfolio. Their team is highly skilled and detail-oriented, ensuring a seamless experience for their partners.",
    name: "",
    affiliate: "https://casinoble.cl/",
  },
  {
    content:
      "We are delighted to establish a partnership with Rhino affiliates, who have demonstrated exceptional professionalism and outstanding support from the beginning. Their impressive conversion rates speak volumes about the quality of their products.",
    name: "",
    affiliate: "https://casinoble.ca/",
  },
  {
    content:
      "It has been an absolute pleasure working with Rhino affiliates. Their top-notch casino brands offer the best player experience and conversion rates. We highly recommend incorporating their casino portfolio on your website.",
    name: "",
    affiliate: "https://casinoble.co.nz/",
  },
  {
    content:
      "Our collaboration with Rhino affiliates was a breeze, thanks to their streamlined and straightforward process. The result exceeded our expectations, and we look forward to working with them again. Their bonuses for the NZ market are highly attractive for top-affiliates.",
    name: "",
    affiliate: "https://bestbonus.co.nz/",
  },
  {
    content:
      "Rhino affiliates hands down the best affiliate program out there. The brands they represent are all top-notch. They are dedicated to making sure their partners are taken care of, which is not something that you often find in the industry these days. If you’re looking for a company to work with, Rhino affiliates should be at the top.",
    name: "",
    affiliate: "https://casinoble.in/",
  },
  {
    content:
      "Rhino Affiliates is always one step ahead, and there's no doubt why everyone is willing to work with them. If you are looking for a reliable partner with responsive managers always here to help and quality brands. than Rhino is the affiliate program you are looking for!",
    name: "",
    affiliate: "https://www.onlinecasinocanucks.com/",
  },

  {
    content:
      "Rhino affiliate program has exceeded my expectations with their generous commissions, top-notch brands, and dedicated support, making it a valuable and rewarding partnership for my affiliate marketing endeavors.",
    name: "",
    affiliate: "https://www.kasiino365.com/",
  },
  {
    content:
      "I've had a great experience with the Tonybet affiliate program. Their support team is really helpful and always there when I need assistance. They provide a bunch of useful tools that have helped me promote their awesome casino brands, which have a high conversion rate.",
    name: "",
    affiliate: "https://www.cogeneracioneficiente.cl/",
  },
  {
    content:
      "Working with Tonybet Affiliates has been a pleasure. They offer attractive brands and demonstrate utmost professionalism. We highly recommend partnering with them for a rewarding and reliable affiliate experience.",
    name: "",
    affiliate: "http://eureka-feci.cl/",
  },
  {
    content:
      "Casinodays is a leading online casino that offers Indian players an exceptional gaming experience. The team of professionals working at Casinodays has created a platform that combines the best in entertainment, security and innovation. Their extensive knowledge and experience gives players access to a variety of games, including popular Indian games such as Teen Patti and Andar Bahar, as well as a wide range of slots, table games and live dealers. If you are not already a customer of this online platform, then you have not seen real luck. We look forward to working with Casinodays team on a long-term basis.",
    name: "",
    affiliate: "https://india-guru.com/",
  },
  {
    content:
      "It is a delightful experience to partner with Rhino Affiliates. Their team is exceptionally amicable and helpful, and they offer top-notch marketing resources along with a highly effective product that yields results. The communication with their team is always pleasant and hassle-free.",
    name: "",
    affiliate: "http://casino.info/",
  },
  {
    content:
      "When it comes to selecting casinos, we never settle for the ordinary. CasinoDays stands out from the rest with its unique features. Additionally, the Rhino Affiliates team displays remarkable efficiency and professionalism.",
    name: "",
    affiliate: "http://casino-on-line.com/",
  },
  {
    content:
      "The Rhino affiliate program is a great addition to our Casinobetting.live online casino guides. Thanks to their amazing brands and products from different markets we’ve managed to expand our awesome website!",
    name: "",
    affiliate: "https://casinobetting.live/",
  },
  {
    content:
      "Promoting online casino services at our website has never been easier with the affiliate program of Rhino affiliates. Since we are always looking for the best online casinos, and they offer exactly what we’re looking for, we enjoy an inspiring collaboration.",
    name: "",
    affiliate: "https://jackpotslayer.com/",
  },
  {
    content:
      "Working with RhinoAfiiliates is more than pleasant for our entire team. We have chosen the company because of its diligent team and forward-looking strategy in New Zealand. Its casino program along with 24/7 support and valuable conversion rates are more than recommended to the wider community. We believe that our cooperation with the brand will continue in the future.",
    name: "",
    affiliate: "https://icasinoreviews.info/",
  },
  {
    content:
      "We've had a great partnership with RhinoAfiiliates. The affiliate managers are knowledgeable and responsive, and the casino bonuses are top-notch. We highly recommend this program to other affiliates.",
    name: "",
    affiliate: "https://toppcasinonorge.com/",
  },
  {
    content:
      "Free Slots Games team recommends Rhino affiliate program. Professional support and good experts.",
    name: "",
    affiliate: "https://free-slots.games/",
  },
  {
    content:
      "Our relationship with Rhino Affiliates has gone from strength to strength in recent years and we are delighted to continue to work with them. They have a number of popular brands featured on Real Money Online Casinos in India | Compared (Aug 2023)  and that comes as no surprise to us",
    name: "",
    affiliate: "https://myonlinecasinos.com/in",
  },
  {
    content:
      "Rhino Affiliates have quickly become one of our favourite partners because of their excellent affiliate program and amazing support. Their products are easy to promote and brings in a high conversion rate, and we believe our partnership with Rhino Affiliates will be a long-lasting one.",
    name: "",
    affiliate: "https://mybetting.in/",
  },
  {
    content:
      "Rhino Affiliates have been our long-time partners in a mutually satisfying and profitable work relationship. They offer top-notch products that appeal greatly to our users, with tons of payment methods and solid customer support.",
    name: "",
    affiliate: "https://casinobonuser.nu/",
  },
  {
    content:
      "Rhino Affiliates offer a strong and reliable product for the Finnish market which our users have enjoyed. Compliments to their affiliate team for making our partnership run smoothly since the beginning!",
    name: "",
    affiliate: "https://kasinosivustoni.com/",
  },
  {
    content:
      "Our recent partnership with Rhino Affiliates has quickly proven to be a positive one. They have a great product for the Chilean market backed by a stellar affiliate team. We highly recommend Rhino Affiliates to others!",
    name: "",
    affiliate: "https://misonlinecasinos.com/cl",
  },
  {
    content:
      "Rhino Affiliates is a fantastic partner to work with in the Japanese market. They offer a strong product backed by a professional and helpful affiliate team. We look forward to our future work together!",
    name: "",
    affiliate: "https://betinjapan.com/",
  },
  {
    content:
      "We highly value our partnership with Rhino Affiliates. They offer a strong, localised product for the JP market which is well supported by their professional affiliate team.",
    name: "",
    affiliate: "https://onlinecasinojapan.com/",
  },
  {
    content:
      "Casino Days has proven to be a big hit for our users on 14 Best Betting Sites Canada | Reviews by MyBettingSites  looking for a reliable casino product. Haris and their affiliate team are a pleasure to work with - we highly recommend them to others!",
    name: "",
    affiliate: "https://mybettingsites.com/ca/articles/ontario-casino-sites",
  },
  {
    content:
      "We are more than happy to recommend Rhino affiliates to others looking for a highly professional and dedicated team with superb online casinos",
    name: "",
    affiliate: "https://fastestwithdrawalcasino.co.uk/",
  },
  {
    content:
      "Our partnership with Rhino Affiliates at Casino-online24.cl has significantly elevated our offerings to the Chilean audience. Their selection of top-tier online casinos aligns perfectly with our promotional goals. The professionalism, dedication, and efficiency demonstrated by the Rhino Affiliates team are pivotal in ensuring a straightforward and mutually beneficial relationship.",
    name: "",
    affiliate: "https://casino-online24.cl/",
  },
  {
    content:
      "At Onlinecasinofinder.nz, we have been fortunate to collaborate with Rhino Affiliates, especially in showcasing premier online casinos. Their handpicked list of online casinos strikes the right chord with our New Zealand users. Their consistent professionalism and commitment to the highest standards have made our partnership both seamless and prosperous.",
    name: "",
    affiliate: "http://onlinecasinofinder.nz/",
  },
  {
    content:
      "LuckyDice has teamed up with Rhino Affiliates and it has exceeded our expectations! Their team's professionalism, exceptional brands, and support have transformed our partnership. A definite recommendation for anyone seeking a trusted and reliable affiliate partner!",
    name: "",
    affiliate: "https://luckydice.in/",
  },
  {
    content:
      "Partnering with Rhino Affiliates has been a great experience for us. Their brands are some of the most popular on our site and the affiliate program is very competitive. We highly recommend them to other affiliates in the industry.",
    name: "",
    affiliate: "https://www.casinot.biz/",
  },
  {
    content:
      "We've been working with Rhino Affiliates for a while now. We've developed a strong partnership thanks to their professional team and quality brands. The program is easy to get started with and offers great commission rates. Our players are big fans!",
    name: "",
    affiliate: "https://www.luotettavat-nettikasinot.com/",
  },
  {
    content:
      "We can highly recommend Rhino to other affiliates in the industry. They have some really great and interesting brands and we regularly receive good feedback from our readers. Their affiliate team are always available if you need them. Looking forward to a long and successful partnership!",
    name: "",
    affiliate: "https://www.suomenkielisetnettikasinot.org/",
  },
  {
    content:
      "Rhino Affiliates have proven to be a valuable partner for us. Their affiliate team are very professional and we have a great relationship with them. It was easy to sign up to their program and commissions are always paid on time. Highly recommended!",
    name: "",
    affiliate: "https://verovapaat.org/",
  },
  {
    content:
      "Our experience working with Rhino Affiliates has been very positive. Their casino brands are popular with our players and lead to high conversion rates. The affiliate platform is easy to navigate and their team is always available to provide support when needed. We highly recommend them to other affiliates.",
    name: "",
    affiliate: "https://gamblermaster.com/fi/",
  },
  {
    content:
      "Casino Days is a top partner for us at NewZealandcasinos.nz with a great product that converts well and a super nice team that is always close at hand. Without a doubt, a partner you will love to work with! Highly recommended!",
    name: "",
    affiliate: "https://newzealandcasinos.nz",
  },
  {
    content:
      "We are very happy to work with the new casinos  of RhinoAffiliates . They constantly challenge us to improve and provide us with great results. Look like We will continue our cooperation for a long time ahead.",
    name: "",
    affiliate: "https://nyecasino2020.com/",
  },
  {
    content:
      "Having joined hands with Rhino Affiliates has brought an immense joy to Casino Leader. Their brands Casino Days and Lucky Spins Casino have stood out of the competition with their plethora of casino games and assortment of rewarding bonuses. The support team is also very professional; we were assisted with everything we needed in the shortest time possible. We look forward to a long term and successful collaboration with them.",
    name: "",
    affiliate: "https://www.casinoleader.com/reviews/casino-days",
  },
  {
    content:
      "Rhino Affiliates is a trustworthy affiliate program with great brands to promote. The team here is skilled as well, and they do offer the best conditions for affiliates.",
    name: "",
    affiliate: "https://www.allgemcasinos.com/",
  },
  {
    content:
      "Casino Days is a great addition to us at Casinogames.nz. With their big game library, smooth design and big welcome offer we are certain our visitors will enjoy playing there. From the start, we have had very good communication with Casino Days representatives and we look forward to a long and successful partnership",
    name: "",
    affiliate: "https://casinogames.nz/",
  },
  {
    content:
      "It's awesome to be part of the Rhino Affiliate family! And we look forward for a long and wealthy partnership.",
    name: "",
    affiliate: "https://nyecasino.com/",
  },
  {
    content:
      "We at Online casino Suomi | Netin paras kasino-opas | Löydä kasino  have been working closely with the team at Rhino Affiliates for quite some time now, and we are genuinely pleased with the partnership. We look forward to continuing our successful cooperation and are optimistic about the continued growth and success it will bring to both parties.",
    name: "",
    affiliate:
      "https://onlinecasinosuomi.com/nettikasinot-ilman-rekisteroitymista/",
  },
  {
    content:
      "At kasinon.live, we have had the pleasure of working closely with the team at Rhino Affiliates for an extended period, and our collaboration has been incredibly rewarding. The dedication and professionalism exhibited by Rhino Affiliates have made our partnership a seamless and successful one. We value the strong connection we've established and are excited about the prospect of continuing our cooperation. The team at Kasinon.live is optimistic about the future, and we look forward to achieving even greater success together.",
    name: "",
    affiliate: "https://kasinon.live/kasino-bonus/",
  },
  {
    content:
      "We cherish our partnership with Rhino Affiliates. Their unparalleled brands and supportive team have enriched our operations and bolstered our joint endeavours.",
    name: "",
    affiliate: "http://livecasinomate.com/",
  },
  {
    content:
      "カジノデイズは魅力的なプロモーションと初心者でもわかりやすいサイト設計でユーザー人気の高いオンカジです。激アツオンラインカジノではカジノデイズの良さを読者に伝えるべく、おすすめオンラインカジノとして紹介しています。",
    name: "",
    affiliate: "https://gekiatsu-casino.com/onlinecasino/popular-onlinecasino/",
  },
  {
    content:
      "ゲーミンラボのおすすめオンラインカジノとして紹介させていただいているカジノデイズは、甘い初回入金ボーナスと豊富なプロモーションが魅力のオペレーターです。イメージキャラクターとなっているサーラちゃんが可愛く、ユーザーの目を引き付けてくれるので宣伝しやすいというメリットも感じています。",
    name: "",
    affiliate: "https://venusscramble.com/media/",
  },
  {
    content:
      "カジノデイズは初回入金ボーナスや入金不要ボーナスをはじめ多数のプロモーションを実施しており、プレイヤーの継続性が高いおすすめのオンラインカジノです。集客後のリターンが大きいのでアフィリエイターにもおすすめしたいオペレーターさんです。",
    name: "",
    affiliate: "https://coac.jp/ranking/",
  },
  {
    content:
      "Expanding our reach was the perfect way to put our strategy into practice, but we needed every bit of assistance we could get. Our plans were going slowly until we joined Rhino affiliate’s affiliate network and became a part of a wider family of advertisers. Additional traffic we received from this source gave us the much-needed wind in the back and allowed us to keep afloat during a critical period. Now our finances have stabilized and we are already thinking about the next ambitious step we could make together with our favorite affiliate team.",
    name: "",
    affiliate: "https://www.slotozilla.com/ca/",
  },
  {
    content:
      "This affiliate program is one of the best in the market. We’ve been cooperating for a while already, and everything has been perfect since the very beginning. Rhino affiliates professional team provided very good marketing materials, which made it easy for us to start promoting their product",
    name: "",
    affiliate: "https://parhaatrahapelit-netissa.com/",
  },
  {
    content:
      "Our experience with Rhino Affiliates has been outstanding. Their seamless operations have led to impressive conversion rates that have truly impressed us. The support from their competitive account managers has added immense value, making our partnership promising for the long run.",
    name: "",
    affiliate: "https://topcasinosreviews.in/",
  },
  {
    content:
      "必勝カジノオンラインＺは、カジノデイズのように初心者・ベテランプレイヤーが楽しみやすいオンラインカジノとパートナーシップを組めたことに感謝しています。丁寧なご対応、そして素晴らしいボーナスを提供するカジノデイズはみんなが大好きなブランドとして確立されており、その業績は私たちにとっても嬉しいニュースです。今後もお互いユーザーが喜ぶ情報を発信できるよう、よろしくお願いいたします。これからもオンラインカジノ情報サイトとして、長くお付き合いできることを楽しみにしています。",
    name: "",
    affiliate: "https://www.casinoonline.jp/",
  },
  {
    content:
      "We recently partnered up with Rhino affiliates and our decision was made super easy since they have slick, sleek and well working brands with great offers for players. 5/5 from us!",
    name: "",
    affiliate: "https://slotsoo.com/fi/",
  },
  {
    content:
      "Rhino Affiliates is one of our best partners on the Finnish pay n play casino market. Their brands convert very well and their manager is extremely helpful. Our team from Pay N Play Kasinot - Löydä paras paynplay casino 2024  highly recommends cooperation with Rhino Affiliates.",
    name: "",
    affiliate: "https://paynplay-kasinot.com/",
  },
  {
    content:
      "Rhino affiliates is operating very trustworthy brands and one of them, Casino Days, is active in Estonian market. We are delighted to work with them, and our collaboration with affiliate team is excellent.",
    name: "",
    affiliate: "https://www.kasiinoguru.com/",
  },
  {
    content:
      "Ever since we started with our cooperation with Rhino affiliates, we have been working towards common goal. Casino Days product offers exciting and fast casino action with bonuses that players want to have. We are certain that Casino Days will have successful future in Estonia.",
    name: "",
    affiliate: "https://www.kiirkasiinod.com/",
  },
  {
    content:
      "Rhino Affiliates is a great partner to have on your side during any marketing campaign. They are able provide us with the support and services that we need, their team truly cares about making sure you're successful in achieving desired results! We couldn't be happier so far- thanks again!",
    name: "",
    affiliate: "https://kasinot.com/",
  },
  {
    content:
      "CasinoDays is one of our nicest partners at bestnzcasino.com. They have a super awesome team who are helpful, nice, and knowledgeable. The product works perfectly, and we have never encountered any problems at all with our collaboration. Highly recommended!",
    name: "",
    affiliate: "https://bestnzcasino.com/",
  },
  {
    content:
      "Rhino Affiliates has been an amazing partner for us. Their creativity and originality are what set them apart from other affiliate programs. This reliable company offers customers excellent support and quick, competent answers at any time. Thanks to the individual approach, they can analyze every detail that directly affects the success of our website.",
    name: "",
    affiliate: "https://sloterman.co.nz/",
  },
  {
    content:
      "With Rhino Affiliates' guidance, we've witnessed a remarkable uptick in our affiliate-generated revenue, and their reliable tracking technology has given us confidence in our performance metrics. We couldn't ask for a more dedicated partner than them.",
    name: "",
    affiliate: "https://wageringadvisors.co.nz/",
  },
  {
    content:
      "Partnering with Rhino Affiliates has been an absolute pleasure for our team at Beste nettcasinoer for 2024 – Norges topp 10 casinosider  Their dedication to fostering mutually beneficial relationships shines through in every interaction. The personalized support we receive, coupled with their robust tracking and reporting tools, has greatly contributed to the success of our affiliate marketing efforts. Rhino Affiliates not only delivers on their promises but consistently goes the extra mile to ensure our satisfaction. We value this partnership and look forward to achieving even greater heights together in the dynamic world of online gaming affiliate marketing.",
    name: "",
    affiliate: "https://www.bestenettcasinoer.com/",
  },
  {
    content:
      "CasinoLandia is thrilled to collaborate with Rhino Affiliates, a team of exceptional professionals whose brands and iGaming Affiliate Program set a new standard in the industry. Our shared commitment to player satisfaction and constructive development align perfectly, laying the groundwork for a fruitful partnership and guaranteed results. We look forward to achieving mutual success and making a significant impact in the iGaming industry together with Rhino Affiliates.",
    name: "",
    affiliate: "https://casinolandia.com/",
  },
  {
    content:
      "Our collaboration with Rhino Partners has been excellent from day 1. Their brands are effective and highly converting, Account Managers are fast and attentive and overall an absolute pleasure to work with. Highly recommended!",
    name: "",
    affiliate: "https://top10-nettikasinot.com/",
  },
  {
    content:
      "After working with Rhino Affiliates for a few years, we undoubtedly classify them as a top tier affiliate program. Our collaboration started with the launch of their first brand, and all that followed were instantly added to our portfolio. All are modern and highly reputable online casinos.",
    name: "",
    affiliate: "https://suomennettikasinot.com/casino/casino-days/",
  },
  {
    content:
      "Little did we know that Rhino Affiliates would one day become one of our favourite affiliate partners when we started our collaboration in 2020. Their brands convert extremely well, the affiliate team is top and commission payments are promptly done every month.",
    name: "",
    affiliate:
      "https://nettikasinot-suomi.com/arvostelu-kokemuksia/buusti-kasino/",
  },
  {
    content:
      "Rhino Affiliates works great for us in the Finnish market. A friendly team of affiliate managers who are fully available any time of the day to assist us. A must-have to any portfolio!",
    name: "",
    affiliate: "https://www.veikkaajat.com/",
  },
  {
    content:
      "Rhino Affiliates has proven to be a game-changer in the online casino affiliate space, offering not only competitive commissions but also a supportive environment that fosters success.",
    name: "",
    affiliate: "https://kasinotilmanrekisteroitymista.com/",
  },
  {
    content:
      "Since the beginning, our engagement with Rhino Affiliates has yielded significant benefits. We at Bonus-Hunt.com are particularly impressed by the high level of professionalism, commitment, and support demonstrated by the Rhino Affiliates team. Their premium, high-converting brands provide a strong basis for our partnership.",
    name: "",
    affiliate: "https://www.bonus-hunt.com/",
  },
  {
    content:
      "Partnering with Rhino Affiliates has been a game-changer, and we look forward to continued growth and success together. What truly sets them apart, however, is their exceptional support team. Their proactive approach and willingness to collaborate have been instrumental in optimizing our marketing strategies and driving results. ",
    name: "",
    affiliate: "https://playsafecasino.ca/",
  },
  {
    content:
      "Beyond the financial benefits, the support and guidance we've received from the Rhino Affiliates team have been invaluable. Their expertise in the iGaming space is evident, and they've been instrumental in helping us navigate the ever-changing landscape of affiliate marketing. We're grateful for this partnership and highly recommend them to anyone looking to take their affiliate business to the next level. ",
    name: "",
    affiliate: "https://chiefcasinos.com/",
  },
  {
    content:
      "Rhino delivered standout conversion and value for us! Their Brands  led to impressive growth. Thanks to Rhino for exceptional results. Highly recommend them for top-notch conversion and value. ",
    name: "",
    affiliate: "https://www.xn--slandcasino-ncb.com/",
  },
  {
    content:
      "Working with Rhino Affiliates is a pleasure, because their brands are very popular amongst our players, and their team is a delight to work with. Their systems are very easy to navigate. We couldn't be happier about our collaboration! ",
    name: "",
    affiliate: "https://uusimmatkasinot.com/",
  },
  {
    content:
      "The conditions of cooperation with Rhino Affiliates deserves 5 stars. The managers are always professional and friendly, delivering excellent services. Their brands are strong and timeless - We can only recommend working with this outstanding partner! ",
    name: "",
    affiliate: "https://www.kasinohai.com/",
  },
  {
    content:
      "We enthusiastically endorse Rhino Affiliates. Their service stands out for its excellence, proving to be an invaluable addition to our partnership, consistently providing valuable assistance and delivering effective results. ",
    name: "",
    affiliate: "https://www.casino.org/reviews/casino-days/",
  },
  {
    content:
      "After several years of collaboration with Rhino Affiliates, we can confidently affirm their professionalism, reliability, and consistent support. They've been a steadfast partner, always available when needed. Our partnership has been incredibly fruitful, and we eagerly anticipate further growth together in the future. ",
    name: "",
    affiliate: "https://www.covers.com/casino/reviews/casino-days",
  },
  {
    content:
      "Gambling.in is thrilled to endorse Rhino Affiliates. Their outstanding service, characterized by prompt responses and comprehensive reports, sets them apart. Their profound understanding of the industry has been incredibly beneficial. We highly recommend them for anyone seeking professional and fruitful affiliate partnerships. ",
    name: "",
    affiliate: "https://www.gambling.in/casinos/casinodays/",
  },
  {
    content:
      "It is a pure pleasure to work with Haris and Ricardo from Rhino Affiliates. Their well-known brands boast high player recognition and offer unparalleled enjoyment, resulting in mutual growth and making collaboration a genuine delight.",
    name: "",
    affiliate: "https://www.casino.in/reviews/casino-days/",
  },
  {
    content:
      "The Rhino Affiliates team offers exceptional support, guaranteeing prompt and reliable service. It is uncommon to encounter such a professional, committed, and forward-thinking team in the realm of affiliate marketing. We eagerly anticipate maintaining our successful partnership with them into the future.",
    name: "",
    affiliate: "https://www.casino.ca/reviews/",
  },
  {
    content:
      "We strive to present to our canadian audience the very best online casinos out there. To include Rhino Affiliates was a no brainer. Onlinecasino.ca takes a great pride to include Casino Days as one of the recommended gambling sites.",
    name: "",
    affiliate: "https://www.onlinecasino.ca/",
  },
  {
    content:
      "Having CasinoDays on our site has been consistently rewarding, thanks to the professionalism demonstrated by Rhino Affiliate Team. We wholeheartedly recommend to work with them.",
    name: "",
    affiliate: "https://www.onlinegambling.ca/regions/ontario/casino-days/",
  },
  {
    content:
      "We are extremely satisfied with our collaboration with Rhino, as they have exceeded all our expectations of a partnership. Quick response and most importantly, lots of positive energy.",
    name: "",
    affiliate: "https://www.sportsbookreview.com/",
  },
  {
    content:
      "We have found immense value in partnering with Rhino Affiliates, benefiting greatly from their extraordinary expertise. We eagerly anticipate a prosperous and enduring collaboration with them.",
    name: "",
    affiliate: "https://www.cardschat.com/",
  },
  {
    content:
      "Delighted to work with Rhino Affiliates! Their platform is user-friendly, with fast, professional support. Looking forward to a fruitful partnership!",
    name: "",
    affiliate: "https://www.nzonlinepokies.co.nz/",
  },
  {
    content:
      "Casino Days stands out as a memorable brand. With its stunning design, extensive game selection, and exceptional affiliate team, it's a pleasure to recommend Casino Days to anyone in the casino traffic industry.",
    name: "",
    affiliate: "https://www.nzonlinecasinos.co.nz/",
  },
  {
    content:
      "We've been eagerly anticipating our partnership with Rhino Affiliates, and it has certainly lived up to expectations. Their online casino offerings are top-notch, featuring excellent bonuses and payment options for all players. We wholeheartedly recommend collaborating with them and their outstanding team.",
    name: "",
    affiliate: "https://www.freespinsnz.co.nz/",
  },
  {
    content:
      "Rhino’s affiliate program is one of the best ones out there! The entire team is filled with absolute professionals who are very keen to come up with new ways to boost results. We are delighted to work with them for many years to come!",
    name: "",
    affiliate: "https://talletusbonukset.net/",
  },
  {
    content:
      "We’ve been working with Rhino Affiliates for a while now and we’d call it a perfect partnership! Both parties understand each other and look for win-win scenarios. Our Account Manager has been nothing but amazing and we are very thankful for the everyday support they provide for us. Our utmost highest recommendations for Rhino Affiliates!",
    name: "",
    affiliate: "https://vironnettikasinot.com/",
  },
  {
    content:
      "Working with Rhino Affiliates since they entered into Estonian market and the co-operation has been very successful. Great business, good and helpful people. Strongly recommended!",
    name: "",
    affiliate: "https://tasutakasiino.com/",
  },
  {
    content:
      "Rhino have a great selection of top tier brands now and are most definitely worth working with. They have a great reputation within the industry and when you combine that with their experienced and responsive management team, it's hard to go wrong.",
    name: "",
    affiliate: "https://kolikkopelienkoti.com/",
  },
  {
    content:
      "As rare as the African Rhino. So is it to work with a highly dedicated and organised affiliate partner. They go well over and beyond. Rhino affiliates will ensure that your needs are always met. Look no further for they will exceed your expectations.",
    name: "",
    affiliate: "https://casinor.com/",
  },
  {
    content:
      "Rhino’s online casinos are top notch and definitely the best on the market. Our customers have liked all their casino brands. Cooperation with the affiliate team has also been impeccable.",
    name: "",
    affiliate: "https://kaikkinettikasinot.fi/",
  },
  {
    content:
      "The casino bonuses that Rhino’s casinos offer are the favorites of our customers. Non-sticky features are a must and Rhino’s online casinos offer them. The casino team is also really professional.",
    name: "",
    affiliate: "https://casino-bonukset.fi/",
  },
  {
    content:
      "We are proud to be affiliated with Rhino Affiliates for the last several years, we can confidently say they are one of the best in the iGaming arena. The partnership provides excellent support, timely payments, and a wide range of high-converting brands to promote. I highly recommend it!",
    name: "",
    affiliate: "http://suomipikakasino.com/",
  },
  {
    content:
      "As a long-time business partner, we can confidently say that Rhino Affiliates is one of the most reliable companies out there. If you're an affiliate looking for a solid partnership, choose Rhino Affiliates with their top notch brands!",
    name: "",
    affiliate: "https://casinocanada.com/",
  },
  {
    content:
      "From the moment we started working with Rhino Affiliates, their team has been incredibly supportive, providing us with all the necessary tools and insights to maximize our campaigns. Their professional attitude and attention to detail are second to none.",
    name: "",
    affiliate: "http://gamblizard.ca/",
  },
  {
    content:
      "The high conversion rates of Rhino Affiliates’ brands are a testament to their deep understanding of the market and commitment to excellence. We're thrilled to continue this partnership and look forward to even greater success.",
    name: "",
    affiliate: "http://casinosters.ca/",
  },
  {
    content:
      "I highly recommend the CasinoDays affiliate program to anyone looking for a reliable and rewarding partnership in the casino space. Kudos to the team for their outstanding support and professionalism!",
    name: "",
    affiliate: "https://eestikasiinod.info/",
  },
  {
    content:
      "At Spilaviti.com, we are extremely satisfied with Rhino. They provide excellent conversions, always pay on time, and are an invaluable partner for us. Thank you, Rhino!",
    name: "",
    affiliate: "https://spilaviti.com/",
  },
  {
    content:
      "Partnering with Rhino Affiliates has been a great experience. They have top quality brands that are popular with our readers, leading to consistently high conversion rates. Their professional and supportive team is always ready to assist when needed and payments are always on time. Highly recommended!",
    name: "",
    affiliate: "https://xn--kasinot-ilman-rekisteritymist-tqc46c.com/",
  },
  {
    content:
      "Casino Days and Big boost are by far the best performing brands in India. We love working with Rhino Affiliates due to their great and swift communication, great deals and payments are always on time. The retention value among our Indian players is also really good. We at Gamblino highly recommend promoting Rhino Affiliates!",
    name: "",
    affiliate: "https://gamblino.com/online-casino/",
  },
  {
    content:
      "Working with Rhino Affiliates has been a fantastic experience. Their responsible and positive attitude, coupled with their unwavering support, makes them an outstanding team to collaborate with. Don’t miss out!",
    name: "",
    affiliate: "https://www.playin.ee/",
  },
  {
    content:
      "Rhino Affiliates has been our partner for a while now and we are extremely happy with our collaboration together. The support and confidence which we are getting is top-notch, and we highly recommend working with them!",
    name: "",
    affiliate: "https://online-casino.ee/",
  },
  {
    content:
      "Rhino Affiliates is a partner we can truly rely on. Their attention to detail, combined with a keen understanding of the iGaming landscape, has delivered excellent results. We highly value our collaboration with their team.",
    name: "",
    affiliate: "https://www.pika-kasinot.co/",
  },
  {
    content:
      "Rhino Affiliates is a superb partner! Their platform is top-notch, and their team is supportive. Once they launched in Estonia we were thrilled to add them to our site.",
    name: "",
    affiliate: "https://kiirkasiinod.co/",
  },
  {
    content:
      "Rhino Affiliates is a close partner of CasinoScores. We send them traffic from CasinoScores, they convert it. Players are happy, and so are we. Their partner team consists of perhaps the best affiliate managers in the industry we’ve had the pleasure to work with; tentative, pro-active, always up for new ideas.",
    name: "",
    affiliate: "https://casinoscores.com/",
  },
  {
    content:
      "Partnering with Rhino Affiliates has been a fantastic experience. Their platform is user-friendly, and their team is always responsive and professional. We’re excited to grow together and highly recommend Rhino Affiliates to anyone looking for a reliable affiliate partner.",
    name: "",
    affiliate: "https://indivisiblegame.com/",
  },
  {
    content:
      "Rhino Affiliates provide us with a high quality product for our audience. With plenty of payment methods and a solid platforms - this is a great pick!",
    name: "",
    affiliate: "https://oddgrenland.no/",
  },
  {
    content:
      "We are glad to be working with Rhino Affiliates. The team has been swift and professional, helping us with anything necessary. These guys take their job seriously. Don't miss out!",
    name: "",
    affiliate: "https://nopeatkasinot.net/",
  },
  {
    content:
      "Rhino Affiliates is a strong partner and they deliver both an attractive affiliate program as well as a highly player-friendly product.",
    name: "",
    affiliate: "https://pomus.net/",
  },
  {
    content:
      "The affiliate team is incredible! Their brand is good and easy to sell, and the support they provide is outstanding. I love working with them.",
    name: "",
    affiliate: "https://kasinopelaajat.com/",
  },
  {
    content:
      "Rhino Affiliates has been our partner for a while now and we are extremely happy with our collaboration together. The support and confidence which we are getting is top-notch, and we highly recommend working with them!",
    name: "",
    affiliate: "https://kasiinoboonused.ee/",
  },
  {
    content:
      "Rhino Affiliates has been an exceptional partner, providing us with consistent support and innovative strategies. Their commitment to delivering high-quality results has significantly boosted our campaigns. The team’s professionalism and attention to detail make them a pleasure to work with. We couldn’t be happier with the collaboration!",
    name: "",
    affiliate: "https://nzcasinohex.com/",
  },
  {
    content:
      "Working with Rhino Affiliates has truly elevated our affiliate marketing efforts. Their expertise in creating tailored strategies and optimizing campaigns has driven remarkable results. The team’s responsiveness and dedication to success set them apart. It’s a partnership we deeply value and trust!",
    name: "",
    affiliate: "https://netticasinohex.com/",
  },
  {
    content:
      "Rhino Affiliates combines innovative thinking with excellent service, making them a standout in the affiliate industry. Their proactive approach and keen market insights have helped us achieve our goals more effectively. Collaborating with their team has been seamless and productive. They are a key part of our success story!",
    name: "",
    affiliate: "https://in.bestindiancasinos.co.in/",
  },
  {
    content:
      "Our partnership with Rhino Affiliates has been a game-changer for our business. Their advanced tools and data-driven approach have streamlined our processes and increased performance. The team is always ready to support and adapt to our needs, ensuring we stay ahead in a competitive market. It’s been a fantastic experience!",
    name: "",
    affiliate: "http://casinohex.org/canada/",
  },
  {
    content:
      "Our Indian players love Casino Days, and we are happy for this partnership. With its wide range of games, fantastic bonuses, and localized payment options, it’s a platform that truly understands the needs of Indian players.",
    name: "",
    affiliate: "https://casinodaysindia.com/",
  },
  {
    content:
      "We are very happy with our cooperation with Casino Days. Their professionalism, reliable platform, and commitment to providing an exceptional gaming experience have made this partnership a pleasure. They offer a variety of games, generous bonuses, and excellent customer support. We look forward to continuing our successful collaboration.",
    name: "",
    affiliate: "https://casinodayscanada.com/",
  },
  {
    content:
      "Rhino Affiliates were recommended to me by a friend in the industry and I really couldn’t be happier about the performance of their brands in the NZ",
    name: "",
    affiliate: "https://new-casinos.co.nz/",
  },
  {
    content:
      "Casino Days is a trusted and valued partner. Their focus on providing a top-notch gaming experience for Ontario players, with secure payments and excellent customer support, makes them an exceptional choice. We’re excited to continue working together!",
    name: "",
    affiliate: "https://casinodaysontario.ca/",
  },
  {
    content:
      "Our collaboration with BigBoost Casino for the Norwegian market has been nothing short of impressive. With excellent conversion rates and strong support, we’re seeing great results from this partnership.",
    name: "",
    affiliate: "https://bigboost-casino.com/",
  },
  {
    content:
      "It’s been a pleasure working with Rhino Affiliates. Their affiliate managers are professional, approachable, and always ready to lend a hand. The Lucky Spins brand in Norway is showing fantastic player value, and we’re excited to continue our successful and long-term partnership.",
    name: "",
    affiliate: "https://luckyspins-casino.com/",
  },
  {
    content:
      "Top10nettikasinot proudly partners with Rhinoaffiliates, a leader in delivering high-performing, player-focused casino products for EU audiences. Their innovative platforms, reliable support, and commitment to excellence consistently exceed our expectations.",
    name: "",
    affiliate: "http://www.top10nettikasinot.fi/",
  },
  {
    content:
      "We’ve been working with Rhino Affiliates for quite a while and the collaboration has been great from the start. Both sides understand our own limits and we look for scenarios that benefit both sides. They are professionals and an absolute pleasure to work with!",
    name: "",
    affiliate: "https://pikakasinot777.com/",
  },
  {
    content:
      "Rhino is a trustworthy and great partner to work with. Team is filled with talented and collaborative individuals who strive to come up with a great product to promote to players. We hope for a long lasting partnership!",
    name: "",
    affiliate: "https://netticasinoita.com/",
  },
  {
    content:
      "With us promoting fast and highly functioning casino products to our readers, Rhino is a great partner to have listed on our site. with fast payments, great promotions and exciting and localized branding it’s exactly what players are looking for. High recommendations!",
    name: "",
    affiliate: "https://nopeat-kotiutukset.com/",
  },
  {
    content:
      "Highly innovate, good promotions, great retention and well established and high quality brands. That’s what Rhino represents and we are extremely happy to work with them. I’m sure we will continue our collaboration for many years to come!",
    name: "",
    affiliate: "https://tervetuliaisbonukset.org/",
  },
  {
    content:
      "This cooperation has been a game-changer for our business. Prompt payments and excellent support are unmatched. Truly a pleasure to work with them!",
    name: "",
    affiliate: "https://online-casinos.nz/",
  },
  {
    content:
      "Were thrilled to work with such a great affiliate program! Seamless process and reliable payouts make them a top choice for affiliates.",
    name: "",
    affiliate: "https://zestfoodtours.co.nz/",
  },
  {
    content:
      "Rhino Affiliates is a top-tier partner—transparent, reliable, and always delivering great results. Their support and deals are unmatched!",
    name: "",
    affiliate: "https://pikakasinotti.com/",
  },
];

export const Testimonials = () => {
  return (
    <div id="testimonials" className="testimonials-section">
      <h1 className="title">Testimonials</h1>
      <div className="content">
        {testimonialsContent.map((item, index) => {
          return (
            <div key={index} className="item">
              <div className="text">
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                {item.name && <p>{item.name}</p>}
              </div>
              <a href={item.affiliate} className="affiliate">
                {item.affiliate}
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Testimonials;
